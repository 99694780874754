import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/TwitchMulti-Logo.png'; // Import the logo

function Home() {
  const [username, setUsername] = useState('');
  const [streamers, setStreamers] = useState([]);
  const navigate = useNavigate();

  const handleAddStreamer = (e) => {
    e.preventDefault();
    if (username.trim() && !streamers.includes(username.trim())) {
      setStreamers([...streamers, username.trim()]);
      setUsername('');
    }
  };

  const handleRemoveStreamer = (streamerToRemove) => {
    setStreamers(streamers.filter((streamer) => streamer !== streamerToRemove));
  };

  const handleSubmit = () => {
    if (streamers.length > 0) {
      navigate(`/watch/${streamers.join('/')}`);
    }
  };

  return (
    <div className="home">
      {/* Include the logo at the top */}
      <img src={logo} alt="TwitchMulti Logo" className="logo" />

      <form onSubmit={handleAddStreamer} className="input-button-container">
        <input
          type="text"
          placeholder="Enter streamer username..."
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <button type="submit" className="add-btn">
          Add <span className="plus-icon">+</span>
        </button>
      </form>

      <div className="streamer-list">
        {streamers.map((streamer, index) => (
          <div key={index} className="streamer-pill">
            {streamer}
            <button onClick={() => handleRemoveStreamer(streamer)}>x</button>
          </div>
        ))}
      </div>

      <button onClick={handleSubmit} disabled={streamers.length === 0} className="watch-btn">
        Watch Streams
      </button>

      {/* Instructions Box */}
      <div className="instructions-box">
        <h2>How To: Use TwitchMulti</h2>
        <p>
          To watch multiple streams, add streamers by typing their usernames and clicking the "Add" button. You can add as many as you like and remove them by clicking the "x" next to the username. When ready, click "Watch Streams."
        </p>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <h2>Frequently Asked Questions</h2>

        <h4>How do I watch multiple Twitch streams at once?</h4>
        <p>Simply enter the usernames of your favorite streamers, click 'Add', and hit 'Watch Streams' to view them all together.</p>

        <h4>Is TwitchMulti free to use?</h4>
        <p>Yes, TwitchMulti is completely free to use. Add as many streamers as you like and watch them live simultaneously.</p>

        <h4>Can I add streamers directly in the URL?</h4>
        <p>Yes! You can enter streamers directly in the URL using the format: twitchmulti.com/watch/streamer1/streamer2/...</p>
      </div>
    </div>
  );
}

export default Home;