import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';

function MultiStream({ showPopover, setShowPopover }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [streamers, setStreamers] = useState(
    location.pathname.replace('/watch/', '').split('/').filter(Boolean)
  );
  const [activeChat, setActiveChat] = useState(streamers[0] || '');
  const [newStreamer, setNewStreamer] = useState('');
  const popoverRef = useRef(null);
  const [gridStyles, setGridStyles] = useState({});
  const [placeholderCount, setPlaceholderCount] = useState(0);

  useEffect(() => {
    if (streamers.length > 0) {
      document.title = `Watching ${streamers.join(', ')} | TwitchMulti`;
    }
  }, [streamers]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false); // Close popover if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [popoverRef, setShowPopover]);

  const addStreamer = (e) => {
    e.preventDefault();
    if (newStreamer.trim()) {
      const updatedStreamers = [...streamers, newStreamer.trim()];
      setStreamers(updatedStreamers);
      setActiveChat(newStreamer.trim());
      setNewStreamer('');
      setShowPopover(false); // Close the popover after adding

      // Update URL
      navigate(`/watch/${updatedStreamers.join('/')}`);
    }
  };

  const removeStreamer = (streamerToRemove) => {
    const updatedStreamers = streamers.filter(
      (streamer) => streamer !== streamerToRemove
    );
    setStreamers(updatedStreamers);
    if (updatedStreamers.length === 0) {
      navigate('/watch');
    } else {
      // Update URL
      navigate(`/watch/${updatedStreamers.join('/')}`);
    }
    if (activeChat === streamerToRemove && updatedStreamers.length > 0) {
      setActiveChat(updatedStreamers[0]);
    } else if (updatedStreamers.length === 0) {
      setActiveChat('');
    }
  };

  // Function to calculate the optimal grid layout
  const resizeGrid = useCallback(() => {
    const numVideos = streamers.length;
    const aspectRatio = 16 / 9;
    const gap = 10; // Gap size between videos

    const viewportWidth = window.innerWidth - 350; // Subtract chat sidebar width
    const viewportHeight = window.innerHeight - 60; // Subtract navbar height

    let columns = Math.floor(
      Math.sqrt((numVideos * viewportWidth) / (viewportHeight * aspectRatio))
    );
    columns = Math.max(1, Math.min(columns, numVideos));

    const rows = Math.ceil(numVideos / columns);

    let videoWidth = (viewportWidth - gap * (columns + 1)) / columns;
    let videoHeight = videoWidth / aspectRatio;

    // If total height exceeds viewport, adjust the video height
    if (videoHeight * rows + gap * (rows + 1) > viewportHeight) {
      videoHeight = (viewportHeight - gap * (rows + 1)) / rows;
      videoWidth = videoHeight * aspectRatio;
    }

    const gridTemplateColumns = `repeat(${columns}, ${videoWidth}px)`;
    const gridAutoRows = `${videoHeight}px`;

    setGridStyles({
      gridTemplateColumns,
      gridAutoRows,
      padding: `${gap}px`,
      width: `${videoWidth * columns + gap * (columns - 1)}px`,
      justifyContent: 'center',
      gap: `${gap}px`,
    });

    // Adjust for incomplete last row
    const totalItems = numVideos;
    const lastRowItems = totalItems % columns || columns;
    const placeholdersNeeded = columns - lastRowItems;

    setPlaceholderCount(placeholdersNeeded);
  }, [streamers]); // Use useCallback and include streamers as dependency

  useEffect(() => {
    resizeGrid();
    window.addEventListener('resize', resizeGrid);
    return () => window.removeEventListener('resize', resizeGrid);
  }, [resizeGrid]);

  return (
    <div className="multistream">
      <div className="video-feeds-container">
        <div className="video-grid" style={gridStyles}>
          {streamers.map((streamer) => (
            <div key={streamer} className="stream">
              <iframe
                title={`Stream of ${streamer}`}
                src={`https://player.twitch.tv/?channel=${streamer}&parent=${window.location.hostname}`}
                allowFullScreen
                frameBorder="0"
              ></iframe>
              <button
                onClick={() => removeStreamer(streamer)}
                className="remove-streamer-btn"
              >
                Remove {streamer}
              </button>
            </div>
          ))}
          {/* Add placeholders to center the last row */}
          {Array.from({ length: placeholderCount }).map((_, index) => (
            <div key={`placeholder-${index}`} className="placeholder"></div>
          ))}
        </div>
      </div>

      <div className="chat-sidebar">
        <div className="chat-tabs">
          {streamers.map((streamer) => (
            <button
              key={streamer}
              onClick={() => setActiveChat(streamer)}
              className={activeChat === streamer ? 'active' : ''}
            >
              {streamer}
            </button>
          ))}
        </div>
        <div className="chat-iframe">
          {activeChat ? (
            <iframe
              title={`Chat for ${activeChat}`}
              src={`https://www.twitch.tv/embed/${activeChat}/chat?parent=${window.location.hostname}`}
              height="100%"
              width="100%"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          ) : (
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              No chat available.
            </p>
          )}
        </div>
      </div>

      {/* Add/Remove Streamers */}
      {showPopover && (
        <div className="add-streamer-popover" ref={popoverRef}>
          <button className="close-btn" onClick={() => setShowPopover(false)}>
            &times;
          </button>
          <form className="add-streamer-form" onSubmit={addStreamer}>
            <input
              type="text"
              value={newStreamer}
              onChange={(e) => setNewStreamer(e.target.value)}
              className="add-streamer-input"
              placeholder="Enter streamer username..."
            />
            <button type="submit">Add Streamer</button>
          </form>

          {streamers.map((streamer) => (
            <div key={streamer} className="streamer-list">
              <span>{streamer}</span>
              <button
                onClick={() => removeStreamer(streamer)}
                className="delete-streamer-btn"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MultiStream;