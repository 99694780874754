import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import MultiStream from './MultiStream';
import './App.css';

function App() {
  const [showPopover, setShowPopover] = useState(false);
  const [theme, setTheme] = useState('light'); // Default theme

  useEffect(() => {
    document.body.className = `${theme}-mode`; // Apply theme class to body
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prev) => (prev === 'light' ? 'dark' : 'light')); // Toggle between light and dark
  };

  const togglePopover = () => {
    setShowPopover((prev) => !prev); // Toggles the state of the popover
  };

  return (
    <Router>
      <div className="app-container">
        {/* Navbar with "Add Streamer" Button and Theme Toggle */}
        <div className="navbar">
          <div className="nav-links">
            <Link to="/">Home</Link>
            <Link to="/watch">Watch</Link>
          </div>

          <div className="theme-toggle">
            <input type="checkbox" onChange={toggleTheme} />
            <label>{theme === 'light' ? 'Dark Mode' : 'Light Mode'}</label>
          </div>

          <button className="add-streamer-btn" onClick={togglePopover}>
            Add Streamer
          </button>
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/watch/*"
            element={
              <MultiStream
                showPopover={showPopover}
                setShowPopover={setShowPopover}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;